import { LinkTo } from "~/assets/icons/LinkTo";

export const Button = ({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled: boolean;
}) => {
  return (
    <button className="flex h-10 flex-row items-center border-b-1 border-[#0E0F0F]">
      {children}
      <div className="ml-2">{!disabled && <LinkTo fill="black" />}</div>
    </button>
  );
};
